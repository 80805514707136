import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/pages/home/Header';
import Heading from 'components/pages/home/Heading';
import CodeExample from 'components/pages/home/CodeExample';
import Footer from 'components/pages/home/Footer';

import fonts from 'helpers/seo/fonts';

import { Endpoint, useCreateEndpointMutation } from 'src/generated/graphql';

const IndexPage: React.FC = () => {
  const [endpointError, setEndpointError] = useState(false);
  const endpointId =
    typeof window !== 'undefined' ? window.localStorage.getItem('endpointId') : null;

  const [createEndpoint, { data }] = useCreateEndpointMutation({
    variables: {
      data: {
        name: 'Endpoint 1',
      },
    },
  });

  useEffect(() => {
    if (!endpointId || endpointError) {
      createEndpoint().then((res) => {
        localStorage.setItem('endpointId', res?.data?.createEndpoint.id as string);
        localStorage.setItem('called', 'false');
      });

      setEndpointError(false);
    }
  }, [createEndpoint, endpointId, endpointError]);

  return (
    <main className='home-page'>
      <Helmet htmlAttributes={{ lang: 'en' }} link={fonts} title='Endpoint Space' />
      <Header />
      <Heading />
      <CodeExample
        endpoint={data?.createEndpoint || ({ id: endpointId } as Endpoint)}
        endpointError={endpointError}
        setEndpointError={setEndpointError}
      />
    </main>
  );
};

export default IndexPage;
