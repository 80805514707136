import React, { FC } from 'react';

const Header: FC = () => {
  return (
    <header className='header container'>
      <div className='logo'>
        <span>endpoint</span>
        <div className='ellipse' />
        <span>space</span>
      </div>
    </header>
  );
};

export default Header;
