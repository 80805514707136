import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type CreateEndpointNotificationDestinationInput = {
  endpointId: Scalars['ID'];
  isVerified: Scalars['Boolean'];
  type: EndpointNotificationDestinationType;
  value: Scalars['String'];
};


export type Endpoint = {
  __typename?: 'Endpoint';
  _count?: Maybe<EndpointCount>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type EndpointCount = {
  __typename?: 'EndpointCount';
  endpointNotificationDestinations: Scalars['Int'];
};

export type EndpointCreateInput = {
  name?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type EndpointNotificationDestination = {
  __typename?: 'EndpointNotificationDestination';
  endpointId: Scalars['String'];
  id: Scalars['String'];
  isVerified: Scalars['Boolean'];
  type: EndpointNotificationDestinationType;
  value: Scalars['String'];
};

export type EndpointNotificationDestinationOrderByWithRelationInput = {
  endpoint?: Maybe<EndpointOrderByWithRelationInput>;
  endpointId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isVerified?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
};

export enum EndpointNotificationDestinationScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndpointId = 'endpointId',
  Id = 'id',
  IsVerified = 'isVerified',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Value = 'value'
}

export enum EndpointNotificationDestinationType {
  Email = 'EMAIL'
}

export type EndpointNotificationDestinationWhereInput = {
  AND?: Maybe<Array<EndpointNotificationDestinationWhereInput>>;
  NOT?: Maybe<Array<EndpointNotificationDestinationWhereInput>>;
  OR?: Maybe<Array<EndpointNotificationDestinationWhereInput>>;
  endpoint?: Maybe<EndpointRelationFilter>;
  endpointId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isVerified?: Maybe<BoolFilter>;
  type?: Maybe<EnumEndpointNotificationDestinationTypeFilter>;
  value?: Maybe<StringFilter>;
};

export type EndpointNotificationDestinationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EndpointOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  redirectUrl?: Maybe<SortOrder>;
};

export type EndpointRelationFilter = {
  is?: Maybe<EndpointWhereInput>;
  isNot?: Maybe<EndpointWhereInput>;
};

export enum EndpointScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Id = 'id',
  Name = 'name',
  RedirectUrl = 'redirectUrl',
  UpdatedAt = 'updatedAt'
}

export type EndpointUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  redirectUrl?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EndpointWhereInput = {
  AND?: Maybe<Array<EndpointWhereInput>>;
  NOT?: Maybe<Array<EndpointWhereInput>>;
  OR?: Maybe<Array<EndpointWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  redirectUrl?: Maybe<StringNullableFilter>;
};

export type EndpointWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EnumEndpointNotificationDestinationTypeFilter = {
  equals?: Maybe<EndpointNotificationDestinationType>;
  in?: Maybe<Array<EndpointNotificationDestinationType>>;
  not?: Maybe<NestedEnumEndpointNotificationDestinationTypeFilter>;
  notIn?: Maybe<Array<EndpointNotificationDestinationType>>;
};


export type Mutation = {
  __typename?: 'Mutation';
  claimEndpoint: Endpoint;
  createEndpoint: Endpoint;
  createEndpointNotificationDestination: EndpointNotificationDestination;
  deleteEndpoint?: Maybe<Endpoint>;
  deleteEndpointNotificationDestination?: Maybe<EndpointNotificationDestination>;
  deleteRecord?: Maybe<Scalars['Boolean']>;
  updateEndpoint?: Maybe<Endpoint>;
};


export type MutationClaimEndpointArgs = {
  where: EndpointWhereUniqueInput;
};


export type MutationCreateEndpointArgs = {
  data: EndpointCreateInput;
};


export type MutationCreateEndpointNotificationDestinationArgs = {
  data: CreateEndpointNotificationDestinationInput;
};


export type MutationDeleteEndpointArgs = {
  where: EndpointWhereUniqueInput;
};


export type MutationDeleteEndpointNotificationDestinationArgs = {
  where: EndpointNotificationDestinationWhereUniqueInput;
};


export type MutationDeleteRecordArgs = {
  where: WhereRecordUniqueInput;
};


export type MutationUpdateEndpointArgs = {
  data: EndpointUpdateInput;
  where: EndpointWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedEnumEndpointNotificationDestinationTypeFilter = {
  equals?: Maybe<EndpointNotificationDestinationType>;
  in?: Maybe<Array<EndpointNotificationDestinationType>>;
  not?: Maybe<NestedEnumEndpointNotificationDestinationTypeFilter>;
  notIn?: Maybe<Array<EndpointNotificationDestinationType>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  endpoint?: Maybe<Endpoint>;
  endpointNotificationDestinations: Array<EndpointNotificationDestination>;
  endpoints: Array<Endpoint>;
  records: Array<Record>;
};


export type QueryEndpointArgs = {
  where: EndpointWhereUniqueInput;
};


export type QueryEndpointNotificationDestinationsArgs = {
  cursor?: Maybe<EndpointNotificationDestinationWhereUniqueInput>;
  distinct?: Maybe<Array<EndpointNotificationDestinationScalarFieldEnum>>;
  orderBy?: Maybe<Array<EndpointNotificationDestinationOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EndpointNotificationDestinationWhereInput>;
};


export type QueryEndpointsArgs = {
  cursor?: Maybe<EndpointWhereUniqueInput>;
  distinct?: Maybe<Array<EndpointScalarFieldEnum>>;
  orderBy?: Maybe<Array<EndpointOrderByWithRelationInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EndpointWhereInput>;
};


export type QueryRecordsArgs = {
  where: WhereRecordsInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Record = {
  __typename?: 'Record';
  body?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  headers?: Maybe<Scalars['JSON']>;
  httpMethod?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type WhereRecordUniqueInput = {
  date: Scalars['String'];
  endpointId: Scalars['ID'];
};

export type WhereRecordsInput = {
  endpointId: Scalars['ID'];
};

export type CreateEndpointMutationVariables = Exact<{
  data: EndpointCreateInput;
}>;


export type CreateEndpointMutation = (
  { __typename?: 'Mutation' }
  & { createEndpoint: (
    { __typename?: 'Endpoint' }
    & Pick<Endpoint, 'id'>
  ) }
);

export type RecordsQueryVariables = Exact<{
  where: WhereRecordsInput;
}>;


export type RecordsQuery = (
  { __typename?: 'Query' }
  & { records: Array<(
    { __typename?: 'Record' }
    & Pick<Record, 'body' | 'createdAt' | 'country' | 'headers' | 'httpMethod' | 'id' | 'ip' | 'userAgent'>
  )> }
);


export const CreateEndpointDocument = gql`
    mutation CreateEndpoint($data: EndpointCreateInput!) {
  createEndpoint(data: $data) {
    id
  }
}
    `;
export type CreateEndpointMutationFn = Apollo.MutationFunction<CreateEndpointMutation, CreateEndpointMutationVariables>;

/**
 * __useCreateEndpointMutation__
 *
 * To run a mutation, you first call `useCreateEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEndpointMutation, { data, loading, error }] = useCreateEndpointMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEndpointMutation(baseOptions?: Apollo.MutationHookOptions<CreateEndpointMutation, CreateEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEndpointMutation, CreateEndpointMutationVariables>(CreateEndpointDocument, options);
      }
export type CreateEndpointMutationHookResult = ReturnType<typeof useCreateEndpointMutation>;
export type CreateEndpointMutationResult = Apollo.MutationResult<CreateEndpointMutation>;
export type CreateEndpointMutationOptions = Apollo.BaseMutationOptions<CreateEndpointMutation, CreateEndpointMutationVariables>;
export const RecordsDocument = gql`
    query records($where: WhereRecordsInput!) {
  records(where: $where) {
    body
    createdAt
    country
    headers
    httpMethod
    id
    ip
    userAgent
  }
}
    `;

/**
 * __useRecordsQuery__
 *
 * To run a query within a React component, call `useRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecordsQuery(baseOptions: Apollo.QueryHookOptions<RecordsQuery, RecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordsQuery, RecordsQueryVariables>(RecordsDocument, options);
      }
export function useRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordsQuery, RecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordsQuery, RecordsQueryVariables>(RecordsDocument, options);
        }
export type RecordsQueryHookResult = ReturnType<typeof useRecordsQuery>;
export type RecordsLazyQueryHookResult = ReturnType<typeof useRecordsLazyQuery>;
export type RecordsQueryResult = Apollo.QueryResult<RecordsQuery, RecordsQueryVariables>;