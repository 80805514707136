import Caveat from 'fonts/Caveat/Caveat-Regular.ttf';
import DMSans from 'fonts/DM_Sans/DMSans-Regular.ttf';
import DMSansBold from 'fonts/DM_Sans/DMSans-Bold.ttf';
import DMSansMedium from 'fonts/DM_Sans/DMSans-Medium.ttf';

const fontsUrls = [Caveat, DMSans, DMSansBold, DMSansMedium];

const fonts = fontsUrls.map((font) => ({
  rel: 'preload',
  as: 'font',
  href: font,
  type: `font/${font.slice(font.lastIndexOf('.') + 1)}`,
  crossOrigin: 'anonymous',
}));

export default fonts;
