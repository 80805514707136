import React, { FC } from 'react';

const Heading: FC = () => {
  return (
    <section className='container heading-section'>
      <h1 className='title'>
        Smart form <span className='gradient'>endpoints</span> for developers.
      </h1>
      <p className='subtitle'>
        The easiest solution to get notifications from forms on your website. Setup in 2 minutes -
        unlimited use.
      </p>
    </section>
  );
};

export default Heading;
